import { useNavigate } from "react-router-dom";
import { GeneralLayout } from "../library/layout/General";
import { SliderBlock } from "../library/components/blocks/Slider";
import { landImages } from "../utils/constants";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Marquee from "react-fast-marquee";

const style = {
  body1: {
    color: "#2f3191",
    fontSize: "1.4em",
    maxWidth: ["sm"],
    fontWeight: "600",
  },
  body2: {
    maxWidth: ["sm"],
    fontSize: "1em",
    fontWeight: "600",
  },
  marquee: {
    backgroundColor: "#238149",
    color: "#ffffff",
    padding: "3px 8px",
  },
};

function Home(props) {
  const navigate = useNavigate();

  return (
    <GeneralLayout
      title="Welcome | Shri Paramhans Advait Mat (Ontario)"
      heading="Welcome"
    >
      <Stack spacing={6} justifyContent="center" alignItems="center">
        <Marquee gradient={false} pauseOnHover={true} speed={40}>          
        </Marquee>
        <SliderBlock images={landImages} />
        <Typography variant="body1" align="center" sx={style.body1}>
          Shri Arti Pooja, Satsang, Seva, Simran aur Dhyaan, shraddha se nitya
          kare, nischay ho kalyaan.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          Great Enlightened Souls and Illuminated Saints incarnate on earth from
          time to time with the mission of spiritual upliftment of mankind, to
          propagate goodness, truthfulness and righteousness in the society and
          to remove evil tendencies and shortcomings of the generation of Their
          times. Same way, His Holiness Shri Shri 108 Shri Swami Advait Anand Ji
          Maharaj, first Benign Spiritual Master incarnated in India and
          established Shri Paramhans Advait Mat.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          To take forward the objects on this holy mission of supreme devotion
          and dedication, and to carry out its religious, spiritual and
          charitable activities SHRI PARAMHANS ADVAIT MAT ONTARIO was
          incorporated in Ontario.
        </Typography>
        <Typography  variant="body2" sx={style.body2}>
        The management has decided to extend the building of Sheetal Dham Ashram at        
        831 W Clover Road,
        Tracy 95376 CA, USA.
        For the construction of extension of above building, we  request all the
        devotees in large to donate some amount for this spiritual object.      
        </Typography>
        <Typography variant="body2" sx={style.body2}>
        Please donate using the link below.
        </Typography>
        <Button
          size="large"
          variant="contained"
          onClick={() => navigate("/donate")}
        >
          Make Donation
        </Button>        
      </Stack>
    </GeneralLayout>
  );
}

export default Home;
