import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { donationSchema } from "../../../utils/validations";
import { purposeVals, countryVals } from "../../../utils/constants";
import NumberFormat from "react-number-format";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import { MuiTelInput } from "mui-tel-input";
import { Box } from "@mui/material";

export const DonationForm = (props) => {
  const { finalise, loader } = props;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(donationSchema),
    mode: "onChange",
    defaultValues: {
      amount: "",
      purpose: "",
      donor: "",
      name: "",
      email: "",
      phone: "",
      dob: new Date("2001-01-01T00:00:00"),
      abn: "",
      street: "",
      addtional: "",
      suburb: "",
      state: "",
      postcode: "",
      country: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [donor, setDonor] = useState("Individual");
  const [amt, setAmt] = useState(0);

  const onSubmit = (values) => {
    const {
      purpose,
      name,
      email,
      phone,
      dob,
      abn,
      street,
      addtional,
      suburb,
      state,
      postcode,
      country,
    } = values;

    if (isValid) {
      finalise({
        variables: {
          input: {
            amount: amt * 100,
            purpose: purpose,
            type: donor,
            name: name,
            email: email,
            phone: phone,
            dob: dob,
            abn: abn,
            street: street,
            addtional: addtional,
            suburb: suburb,
            state: state,
            postcode: postcode,
            country: country.label,
          },
        },
      });
    }
  };

  const changeDonor = (event) => {
    setDonor(event.target.value);
    setValue("donor", event.target.value);
  };

  useEffect(() => {
    if (loader) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loader]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container gap={2} justifyContent="center" alignItems="center">
        {/* amount */}
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <NumberFormat
                  id="amount-field"
                  label="Amount (USD)"
                  variant="outlined"
                  customInput={TextField}
                  thousandSeparator={true}
                  prefix={"US$ "}
                  onValueChange={(values) => setAmt(values.value)}
                  {...field}
                  error={errors.amount ? Boolean(errors.amount) : false}
                  helperText={errors.amount?.message}
                />
              </FormControl>
            )}
            name="amount"
            control={control}
          />
        </Grid>
        {/* purpose */}
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="purpose-select-label">Purpose</InputLabel>
                <Select
                  labelId="purpose-select-label"
                  id="purpose-select"
                  label="Purpose"
                  error={errors.purpose ? Boolean(errors.purpose) : false}
                  {...field}
                >
                  {purposeVals.map((data, index) => (
                    <MenuItem value={data} key={index}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={errors.purpose ? Boolean(errors.purpose) : false}
                >
                  {errors.purpose?.message}
                </FormHelperText>
              </FormControl>
            )}
            name="purpose"
            control={control}
          />
        </Grid>
        {/* divider */}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* donor */}
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="donor-select-label">Type</InputLabel>
                <Select
                  labelId="donor-select-label"
                  id="donor-select"
                  label="donor"
                  error={errors.donor ? Boolean(errors.donor) : false}
                  {...field}
                  onChange={changeDonor}
                >
                  <MenuItem value="Individual" key={0}>
                    Individual
                  </MenuItem>
                  <MenuItem value="Organisation" key={1}>
                    Organisation
                  </MenuItem>
                </Select>
                <FormHelperText
                  error={errors.donor ? Boolean(errors.donor) : false}
                >
                  {errors.donor?.message}
                </FormHelperText>
              </FormControl>
            )}
            name="donor"
            control={control}
          />
        </Grid>
        {/* name */}
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  id="name-field"
                  label="Name"
                  variant="outlined"
                  error={errors.name ? Boolean(errors.name) : false}
                  helperText={errors.name?.message}
                  {...field}
                />
              </FormControl>
            )}
            name="name"
            control={control}
          />
        </Grid>
        {/* dob */}
        {donor === "Individual" && (
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                name="dob"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <FormControl fullWidth>
                    <DesktopDatePicker
                      id="dob-date-picker"
                      label="Date of Birth"
                      inputFormat="DD/MM/YYYY"
                      error={errors.dob ? Boolean(errors.dob) : false}
                      renderInput={(params) => <TextField {...params} />}
                      {...rest}
                    />
                    <FormHelperText
                      error={errors.dob ? Boolean(errors.dob) : false}
                    >
                      {errors.dob?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}
        {/* abn */}
        {donor === "Organisation" && (
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth>
                  <TextField
                    id="abn-field"
                    label="ABN"
                    variant="outlined"
                    error={errors.abn ? Boolean(errors.abn) : false}
                    helperText={errors.abn?.message}
                    {...field}
                  />
                </FormControl>
              )}
              name="abn"
              control={control}
            />
          </Grid>
        )}
        {/* email */}
        <Grid item xs={12} md>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  id="email-field"
                  label="Email"
                  type="email"
                  variant="outlined"
                  error={errors.email ? Boolean(errors.email) : false}
                  helperText={errors.email?.message}
                  {...field}
                />
              </FormControl>
            )}
            name="email"
            control={control}
          />
        </Grid>
        {/* phone */}
        <Grid item xs={12} md>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <MuiTelInput
                  id="phone-field"
                  label="Phone"
                  defaultCountry="US"
                  preferredCountries={["US"]}
                  error={errors.phone ? Boolean(errors.phone) : false}
                  helperText={errors.phone?.message}
                  {...field}
                />
              </FormControl>
            )}
            name="phone"
            control={control}
          />
        </Grid>
        {/* street */}
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  id="street-field"
                  label="Address Line 1"
                  variant="outlined"
                  error={errors.street ? Boolean(errors.street) : false}
                  helperText={errors.street?.message}
                  {...field}
                />
              </FormControl>
            )}
            name="street"
            control={control}
          />
        </Grid>
        {/* addtional */}
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  id="addtional-field"
                  label="Address Line 2"
                  variant="outlined"
                  error={errors.addtional ? Boolean(errors.addtional) : false}
                  helperText={errors.addtional?.message}
                  {...field}
                />
              </FormControl>
            )}
            name="addtional"
            control={control}
          />
        </Grid>
        {/* suburb */}
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  id="suburb-field"
                  label="City"
                  variant="outlined"
                  error={errors.suburb ? Boolean(errors.suburb) : false}
                  helperText={errors.suburb?.message}
                  {...field}
                />
              </FormControl>
            )}
            name="suburb"
            control={control}
          />
        </Grid>
        {/* state */}
        <Grid item xs={12} md>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  id="state-field"
                  label="State"
                  variant="outlined"
                  error={errors.state ? Boolean(errors.state) : false}
                  helperText={errors.state?.message}
                  {...field}
                />
              </FormControl>
            )}
            name="state"
            control={control}
          />
        </Grid>
        {/* postcode */}
        <Grid item xs={12} md>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  id="postcode-field"
                  label="Postal Code"
                  variant="outlined"
                  error={errors.postcode ? Boolean(errors.postcode) : false}
                  helperText={errors.postcode?.message}
                  {...field}
                />
              </FormControl>
            )}
            name="postcode"
            control={control}
          />
        </Grid>
        {/* country */}
        <Grid item xs={12}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id="country-field"
                onChange={(event, country) => {
                  onChange(country);
                }}
                options={countryVals}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                isOptionEqualToValue={(option, value) =>
                  value === undefined ||
                  value === "" ||
                  option.label === value.label
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    fullWidth
                    error={errors.country ? Boolean(errors.country) : false}
                    helperText={errors.country?.message}
                  />
                )}
              />
            )}
            onChange={([event, data]) => {
              return data;
            }}
            name="country"
            control={control}
          />
        </Grid>
        {/* submit */}
        <Box>
          <Grid item xs={12}>
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              {"Confirm & Pay"}
            </LoadingButton>
          </Grid>
        </Box>
      </Grid>
    </form>
  );
};
