import { useNavigate } from "react-router-dom";
import { GeneralLayout } from "../library/layout/General";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function Error(props) {
  const navigate = useNavigate();

  return (
    <GeneralLayout
      title="Page not found | Shri Paramhans Advait Mat (Ontario)"
      heading="Not found!"
    >
      <Box
        sx={{
          maxWidth: "sm",
          mx: "auto",
          background: "#ffffff",
          padding: "30px",
          borderRadius: "10px",
          textAlign: "center",
        }}
      >
        <p>
          The page or the resource you are looking has been moved or deleted!
        </p>
        <Button onClick={() => navigate("/")}>Back to home</Button>
      </Box>
    </GeneralLayout>
  );
}

export default Error;
