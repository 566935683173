import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLazyQuery, gql } from "@apollo/client";
import { GeneralLayout } from "../library/layout/General";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";

const DONATION_DETAILS = gql`
  query GetDonation($intent: String!) {
    donation: getDonation(intent: $intent) {
      id
      no
      name
      amount
      purpose
      email
    }
  }
`;

const style = {
  container: {
    maxWidth: "sm",
    mx: "auto",
    background: "#ffffff",
    padding: "30px",
    paddingBottom: "50px",
    borderRadius: "10px",
    textAlign: "center",
  },
  text1: {
    color: "#2f3191",
    fontSize: "1.4em",
    maxWidth: ["sm"],
    fontWeight: "600",
    padding: "10px 20px",
  },
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function Success(props) {
  const [number, setNumber] = useState(null);
  const [amount, setAmount] = useState(0);
  const [purpose, setPurpose] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [link, setLink] = useState("");
  const [id, setId] = useState(null);

  const [searchParams] = useSearchParams();

  const status = searchParams.get("redirect_status");
  const intent = searchParams.get("payment_intent");

  const [dondationDetails, { data, error }] = useLazyQuery(DONATION_DETAILS, {
    variables: { intent },
  });

  useEffect(() => {
    if (status === "succeeded") {
      dondationDetails();
      console.log(status);
    }
  }, [status, dondationDetails]);

  useEffect(() => {
    if (data) {
      console.log(data?.donation);
      setNumber(data?.donation?.no);
      setName(data?.donation?.name);
      setAmount(data?.donation?.amount);
      setPurpose(data?.donation?.purpose);
      setEmail(data?.donation?.email);
      setId(data?.donation?.id);
    }
  }, [data]);

  useEffect(() => {
    if (id)
      setLink(
        `https://web.shriparamhansadvaitmatontario.logicsync.app/receipt/${id}.pdf`
      );
  }, [id]);

  useEffect(() => {
    if (error) console.log(error);
  }, [error]);

  return (
    <>
      {status === "succeeded" && data ? (
        <GeneralLayout
          title="Payment Successful | Shri Paramhans Advait Mat (Ontario)"
          heading="Payment Successful!"
        >
          <Box sx={style.container}>
            <Typography variant="body1" align="center" sx={style.text1}>
              Thank you donating {name}. <br />
              You have donated {formatter.format(amount / 100)} for {purpose}.
            </Typography>
            <Typography variant="body1" align="center" sx={style.text1}>
              Jai Sachidanand Ji
            </Typography>
            <Box
              sx={{
                maxWidth: "280px",
                textAlign: "center",
                paddingBottom: "1px",
                margin: "0 auto",
              }}
            >
              <p>
                <strong>Donation ID:</strong> {id}
              </p>
              <Divider />
              <p>
                <strong>Receipt #:</strong> {number}
              </p>
              <Divider />
              <p>
                <strong>Amount:</strong> {formatter.format(amount / 100)}
              </p>
            </Box>
            <p>
              Your receipt along with Aashirvad (pdf) will be emailed to you at{" "}
              <strong>{email}</strong> shortly.
            </p>
            <Link href={link} target="_blank">
              Download receipt
            </Link>
          </Box>
        </GeneralLayout>
      ) : (
        <GeneralLayout
          title="Unconfirmed Status | Shri Paramhans Advait Mat (Ontario)"
          heading="Please wait..."
        >
          <Box sx={style.container}>
            <p>We are processing the donation!</p>
          </Box>
        </GeneralLayout>
      )}
    </>
  );
}

export default Success;
